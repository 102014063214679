import * as React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import axios from '../../../http-request';
import { showLoading, hideLoading } from '../../../forms/loading-screen/actions';
import iconUploader from '../../../master-layout/images/uploader.svg';
import styles from '../../../styles/styles';
import { uploadDefaults } from '../../../shared/helper-functions';
import {
    fileTypes,
    fileTypesExtended,
    extensionCheckRegEx,
} from '../../../forms/form-components/file-upload/file-upload';
const { env } = require('../../../env/env');

const outstandingDocuments = ({ claimInfo, classes, hideLoading, setLoaded, showLoading }) => {
    if (claimInfo.outstandingDocuments == null || claimInfo.outstandingDocuments.length === 0) {
        return null;
    }

    return (
        <Grid container item xs={12} style={{ marginTop: '24px' }}>
            {claimInfo.outstandingDocuments.map(outstandingGroup => {
                if (
                    outstandingGroup == null ||
                    outstandingGroup.documents == null ||
                    outstandingGroup.documents.length === 0
                ) {
                    return null;
                }
                return (
                    <React.Fragment key={`element-${outstandingGroup.groupName}`}>
                        <Typography
                            className={classes.cardSubHeadingSubtle}
                            style={{ marginBottom: '10px' }}
                        >
                            Outstanding requirements for {outstandingGroup.groupName}
                        </Typography>
                        {outstandingGroup.documents.map((doc, ind) => {
                            const fileName = `file-${doc.id}-${doc.name
                                .toLowerCase()
                                .split(' ')
                                .join('')}`;
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.boxUploaderBox}
                                    key={`doc${ind}`}
                                >
                                    <span
                                        className={classes.boxUploaderLink}
                                        onClick={evt => setup(evt, fileName)}
                                    >
                                        <img
                                            alt="Upload"
                                            src={iconUploader}
                                            style={{
                                                verticalAlign: 'middle',
                                                paddingRight: '10px',
                                            }}
                                        />
                                        Upload {doc.description
                                            ? `${doc.name.toLowerCase()} - ${doc.description.toLowerCase()}`
                                            : doc.name.toLowerCase()}
                                    </span>
                                    <input
                                        type="file"
                                        id={fileName}
                                        style={{ display: 'none' }}
                                        onChange={() => {
                                            return handleFilesUpload(
                                                claimInfo.claimId,
                                                doc.id,
                                                doc.name.toLowerCase().split(' ').join(''),
                                                showLoading,
                                                setLoaded,
                                                hideLoading
                                            );
                                        }}
                                    />
                                </Grid>
                            );
                        })}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};

function setup(evt, docName) {
    const fileElem = document.getElementById(docName);
    fileElem.click();
    evt.preventDefault();
}

async function handleFilesUpload(claimId, docId, docName, showLoading, setLoaded, hideLoading) {
    // @ts-ignore
    let file = document.getElementById(`file-${docId}-${docName}`).files[0];
    if (file == null) {
        return;
    }
    const formData = new FormData();

    if (fileTypesExtended.indexOf(`.${extensionCheckRegEx.exec(file.name)[1]}`) === -1) {
        toastr.error('', `Only files with extensions of ${fileTypes.join(', ')} are allowed.`, {
            timeOut: 0,
        });
        return;
    }
    try {
        if (file.size > uploadDefaults.fileLimit) {
            throw new Error(`File "${file.name}" is too large`);
        } else {
            formData.append('files[]', file);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        showLoading();
        const response = await axios.post(uploadDefaults.uploadUrl, formData, config);
        const payload = {
            id: docId,
            claimId: claimId,
            fileName: response.data[0].name,
            mimeType: response.data[0].mimeType,
            url: response.data[0].url,
        };
        const url = `${env.REACT_APP_API_URL}/claim-form-document`;
        await axios.post(url, payload);
        setLoaded(false);
        hideLoading();
    } catch (err) {
        setLoaded(false);
        hideLoading();
        toastr.error('', 'There was a problem uploading this document.', { timeOut: 0 });
        return;
    }
}

const mapDispatchToProps = {
    showLoading,
    hideLoading,
};

const mapStateToProps = () => ({});

export const OutstandingDocuments = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(outstandingDocuments));
