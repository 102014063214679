import React, { useState } from 'react';
import { IconButton, TextField as MuiTextField, Typography, makeStyles } from '@material-ui/core';
import { nameToLabel, processMetaForErrors } from '../..';
import { getValidatedLabel } from '../../form-component-utilities/label-validator/label-validator';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';

const useStyles = makeStyles({
    inputGroup: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: '4px',
        maxWidth: '200px',
    },
    input: {
        textAlign: 'center',
        outline: 'none',
        border: 'none',
        padding: '12px 0',
        fontSize: '18px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px',
    },

    cssLabel: {
        color: '#0D1325',
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '40px',
        marginBottom: -10,
    },
    textField: {
        '& input': {
            textAlign: 'center',
          },
    },
    cssOutlinedInputShaded: {
        '&$cssFocused $notchedOutline': {
            borderColor: `#36B37E`,
            borderWidth: '1px',
        },
        backgroundColor: '#F0F1F4',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `#36B37E`,
            borderWidth: '1px',
        },
        backgroundColor: '#FFFFFF',
    },
    cssFocused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    disabled: {
        opacity: '0.6',
        cursor: 'not-allowed'
      }
});

const NumberCounter = ({
    id,
    input,
    classes,
    meta,
    label,
    hideLabel,
    helperText,
    mask,
    guide,
    placeholderChar,
    keepCharPositions,
    pipe,
    showMask,
    inputProps,
    InputProps,
    shaded,
    placeholder,
    ...rest
}) => {
    let { name, value, onChange, ...restInput } = input;
    const { errorMessage, showError } = processMetaForErrors(meta);

    const maskProps = mask
        ? {
              mask,
              guide,
              placeholderChar,
              keepCharPositions,
              pipe,
              showMask,
          }
        : {};

    inputProps = {
        ...restInput,
        ...inputProps,
        ...maskProps,
    };

    const [count, setCount] = useState(value || 0);
    const customClasses = useStyles();

    const handleInputChange = (event) => {
        const newValue = event.target.value === '' ? '' : parseInt(event.target.value);

        if (!isNaN(newValue) || newValue === '') {
            setCount(newValue);
            onChange(newValue?.toString());
        }
    };

    const increment = () => {
        const currentValue = count === "" ? 0 : parseInt(count);
        const newValue = currentValue + 1;
        value = newValue;
        onChange(newValue);
        setCount(newValue.toString());
        return value;
    };

    const decrement = () => {
        if (count >= 0) {
            const currentValue = count === "" ? 0 : parseInt(count);
            const newValue = Math.max(currentValue - 1, 0);
            value = newValue;
            setCount(newValue.toString());
            return value;
        }

        setCount('0');
    };

    return (
        <div className={customClasses.textField}>
            {!hideLabel && (
                <Typography
                    className={customClasses.cssLabel}
                    style={showError ? { color: '#cd003d' } : {}}
                >
                    {!label
                        ? getValidatedLabel(rest, nameToLabel({ label, name }))
                        : getValidatedLabel(rest, label)}
                </Typography>
            )}
            <div className={customClasses.inputGroup}>
                <MuiTextField
                    {...rest}
                    id={id || name}
                    name={name}
                    placeholder='0'
                    data-testid="valueOfCounter"
                    helperText={showError ? errorMessage : helperText}
                    className={(customClasses.input )}
                    classes={{root: customClasses.textField}}
                    error={showError}
                    InputProps={{
                        ...InputProps,
                        style: {textAlign: 'center !important'},
                        classes: {
                            root: shaded ? customClasses.cssOutlinedInputShaded : customClasses.cssOutlinedInput,
                            focused: customClasses.cssFocused,
                            notchedOutline: customClasses.notchedOutline,
                        },
                        startAdornment: (
                            <><IconButton
                                className={(count === '' || count === '0' || count === undefined) ? customClasses.disabled : ''}
                                style={{ color: '#002B99' }}
                                onClick={decrement}
                                Name="decrement"
                                data-testid="decrement-button">
                                    <RemoveCircleOutlineRoundedIcon />
                                </IconButton></>
                        ),
                        endAdornment: (
                            <IconButton style={{ color: '#002B99' }} onClick={ increment } Name="increment" data-testid="increment-button">
                                <AddCircleOutlineRoundedIcon />
                            </IconButton>
                        ),
                    }}
                    inputProps={inputProps}
                    onChange={handleInputChange}
                    value={count}
                    defaultValue={count}
                    variant="outlined"
                    fullWidth
                    FormHelperTextProps={{
                        style: { marginLeft: 0, },
                    }}
                />
            </div>
        </div>
    );
};

export { NumberCounter };
