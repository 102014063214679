import React from 'react';

import { Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { ClaimStepper } from '../claim/claim-stepper';
import { HorizontalStepper } from '../common/horizontal-stepper';
import { Introduction } from './introduction';
import { ClaimTypes } from '../../shared/lookups';
import { ClaimantDetails } from './claimant-details/claimant-details';
import { ApplicationProcess } from './application-process';
import informationIcon from '../../styles/icons/information.svg';
import { PolicyOwnerDetails } from '../living-lifestyle/policy-holder/policyowner-details';
import { LifeAssuredDetails } from './life-assured/life-assured-details';
import { PaymentDetails } from './payment/payment-details'
import { getSouthAfricanAddress } from '../../shared/helper-functions';
import { Declaration } from './declaration/declaration';
import { Summary } from './summary/summary'

export const lifeStyleComponents = [
    {
        name: 'Introduction',
        information:
            'We outline the documents we need to process your claim, as well as any additional document(s) which might be relavant.',
        component: Introduction,
    },
    {
        name: 'Claimant Details',
        information: 'We will need personal details about the Claimant.',
        component: ClaimantDetails,
    },
    {
        name: 'Policyholder',
        information: 'We will require personal details about the Policy Owner.',
        component: PolicyOwnerDetails,
    },
    {
        name: 'Life Assured',
        information:
            'We will need details about the Life Assured.',
        component: LifeAssuredDetails,
    },
    {
        name: 'Payment',
        information:
            'We will need details about the Payments.',
        component: PaymentDetails,
    },
    {
        name: 'Summary',
        information: 'This is a chance to review everything before you submit.',
        component: Summary,
    },
    {
        name: 'Declaration',
        information: 'We will need you to confirm that you agree with our terms and conditions.',
        component: Declaration,
    },
];

function claimHeader({ classes, activeStepIndex, components, stepThrough, updateStepIndexAll }) {
    if (activeStepIndex === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <ApplicationProcess sectionIndex={activeStepIndex} />
            <HorizontalStepper
                components={components}
                activeIndex={activeStepIndex}
                stepThrough={stepThrough}
                updateIndex={updateStepIndexAll}
            />
            <SectionNotification
                classes={classes}
                activeStepIndex={activeStepIndex}
                length={components.length}
            />
        </React.Fragment>
    );
}

function SectionNotification({ classes, activeStepIndex, length }) {
    if (activeStepIndex === length - 1) {
        return null;
    }

    const notification =
        activeStepIndex === 5
            ? 'Please check and ensure all the information you provided is correct. You may also print this for your records.'
            : 'Please complete all fields marked with a *';

    return (
        <div className={classes.requiredInformation}>
            <img src={informationIcon} alt="information" />
            <Typography className={classes.requiredInformationText}>{notification}</Typography>
        </div>
    );
}

export function validateLivingLifeStyleClaim(form, activeStep, customStyling) {
    const policyHolderDetails = form.policyholder || {};
    const existingIndividual = policyHolderDetails.individual || {};
    const existingAddressDetails = existingIndividual.addressDetails || getSouthAfricanAddress();
    const existingLifeAssured = form.lifeAssured || {};
    const lifeAssuredAddressDetails = existingLifeAssured.addressDetails || getSouthAfricanAddress();
    const sessionStep = sessionStorage.getItem('activeComponentStep');
    const activeComponentStep = activeStep.name + (sessionStep ? ' - ' + sessionStep : '');

    form.activeStep = Array.isArray(form.activeStep) ? form.activeStep : [form.activeStep].filter(Boolean);

    if (!form.activeStep.includes(activeComponentStep)) {
        form.activeStep.push(activeComponentStep);
    }

    const sectionTitles = {
        declaration: '<b>Living Lifestyle Claim Declaration</b>',
        unclaimedBenefits: '<b>Information on unpaid or unclaimed benefits</b>',
        acceptanceTerms: '<b>Acceptance of Terms and Conditions</b>',
    };
        const declarationBodyString = `
        <div class="${customStyling.paragraph}">
            I, as the claimant(s), claim the benefits of the policy(ies).
        </div>
        <br />
        <div class="${customStyling.paragraph}">
            I agree that:
        </div>
        <ul class="${customStyling.paragraph}">
            <li>My/our personal details relating to this claim may be shared by the trustees with other claimants who
                may have an interest in these benefits. I/We understand that this information is disclosed to such claimants
                as they may have an interest in how trustees make their recommendations.
            </li>
            <li>Any written statements, affidavits, and supporting documents provided in support of this claim will form
                part of this claim. The supply of this form or of any other forms is not an admission by Liberty that there
                was assurance in force on the life of the deceased member or a waiver of any of Liberty's rights or defenses under
                the law.
            </li>
            <li>Any benefits payable in respect of this claim will be forfeited if I/We, or anyone
                acting on my/our behalf or with my/our knowledge, have withheld
                any material facts or submitted any false information in respect of the claim.
            </li>
            <li>Upon payment by Liberty of the benefits claimed by me/us, Liberty will be released from all liability
                in respect of such benefits.
            </li>
        </ul>
        <div class="${customStyling.paragraph}">
            Once the benefits have been paid, I agree that Liberty Group Limited (and
            its successors) are no longer responsible for any other claims or liabilities
            relating to these policy/ies benefits.
        </div>
    `;
    const unclaimedBenefitsBodyString = `
        <div class="${customStyling.paragraph}">
            It is the responsibility of members to ensure that Liberty always has up-to-date contact information (including that of any potential beneficiary).
            <br /><br />
            Where Liberty becomes aware that benefits are payable, we will seek to
            communicate at the last address provided to us.
            <br /><br />
            If this is unsuccessful, Liberty will take reasonable steps to find those
            who are entitled to the benefits, which steps may entail the appointment by
            Liberty of external trace agents.
            <br /><br />
            I/We consent to Liberty appointing an external tracing agent and providing
            them with the necessary information. A tracing and management fee as determined at the
            time of tracing may be deducted by Liberty from the benefits payable.
            <br /><br />
            <b>Note that in certain circumstances, an additional amount may be payable
                by Liberty in relation to any late payment.</b>
        </div>
    `;
    const acceptanceTermsBodyString = `
       <div style="padding: 0; width: 95%; padding-top: 4px;">
            <label style="display: flex; align-items: center;">
                <input type="checkbox" checked disabled style="margin-right: 8px;" />
                <span style="font-size: 16px; line-height: 20px;">
                    I declare that the answers and statements are true to the best of my
                    knowledge and belief, and that I have withheld no material fact.
                </span>
            </label>
        </div>
    `;
    const declarationString = `
        ${sectionTitles.declaration}:
        ${declarationBodyString}
        <br />
        ${sectionTitles.unclaimedBenefits}:
        ${unclaimedBenefitsBodyString}
        <br />
        ${sectionTitles.acceptanceTerms}:
        ${acceptanceTermsBodyString}
    `;

    return {
        ...form,
        policyholder: {
            ...policyHolderDetails,
            individual: {
                ...existingIndividual,
                addressDetails: existingAddressDetails,
            }
        },
        lifeAssured: {
            ...existingLifeAssured,
            addressDetails: lifeAssuredAddressDetails,
        },
        activeStep: form.activeStep,
        declarationInformation: declarationString
    };
}
const defaultInitialValues = { claimType: ClaimTypes.LivingLifestyleClaim };

export const LivingLifeStyleClaim = withStyles(styles)(() => {
    return <ClaimStepper defaultInitialValues={defaultInitialValues} headerComponent={claimHeader} />;
}
);
